main {
  --logo-height: 200px;
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 1.5fr;
  &:not(.sub) {
    @media only screen and (max-width: 650px) {
      --logo-height: 60px;
      grid-template-rows: auto 2fr;
    }
  }
  section {
    display: flex;
    flex-direction: column;
    > * {
      margin: auto;
    }
  }
  &.sub {
    --logo-height: 80px;
    grid-template-rows: auto 1.5fr;
    @media only screen and (max-width: 650px) {
      --logo-height: 40px;
    }
    section {
      > * {
        margin: 0 auto;
      }
    }
  }
  .meetings-iframe-container {
    width: 100%;
  }
}
header {
  display: flex;
  padding: 30px 20px;
  svg {
    width: auto;
    height: var(--logo-height);
    margin: auto;
    max-height: 100%;
  }
}
.main-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 60px;
  a {
    text-decoration: none;
    color: #000000;
    width: 600px;
    background-color: #FFFFFF;
    border: 3px solid #000000;
    box-shadow: 10px 10px #ff563f;
    padding: 20px;
    position: relative;
    z-index: 11;
    transition: 200ms;
    border-radius: 100px;
    text-align: center;
    font-size: 2.5em;
    font-weight: 300;
    margin: 0 auto;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: 5px 5px #ff563f;
    }
    &:active {
      transform: translate(10px, 10px);
      box-shadow: none;
    }
    &:visited {
      color: #000000;
    }
    @media only screen and (max-width: 900px) {
      width: 450px;
      font-size: 2em;
    }
    @media only screen and (max-width: 650px) {
      width: 350px;
      font-size: 1.5em;
    }
    @media only screen and (max-width: 550px) {
      width: 350px;
    }
    @media only screen and (max-width: 450px) {
      width: 250px;
      font-size: 1.2em;
    }
  }
}

